/* You can add global styles to this file, and also import other style files */

// :root {
//   --vl-Primary-color: #00952e;
// }

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-banesco {
  background-color: #00952e;
  color: white;
}

.calendar-disable-date {
  pointer-events: none;
  * {
    color: rgba(0, 0, 0, 0.2) !important;
  }
}

.vl-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
